*{
  font-family: Roboto;
}

.testimonials-clean {
  color: #313437;
}

.testimonials-clean p {
  color: white;
}

.testimonials-clean h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width:767px) {
  .testimonials-clean h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.testimonials-clean .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.testimonials-clean .intro p {
  margin-bottom: 0;
}

.testimonials-clean .people {
  padding: 50px 0 20px;
}

.testimonials-clean .item {
  margin-bottom: 32px;
}

@media (min-width:768px) {
  .testimonials-clean .item {
    height: 320px;
  }
}

@media (min-width:900px) {
  .testimonials-clean .item {
    height: 400px;
  }
}

.testimonials-clean .item .box {
  padding: 20px;
  background-color: #24ABE2;
  position: relative;
  border-radius: 5px;
}

.testimonials-clean .item .box:after {
  content: '';
  position: absolute;
  left: 30px;
  bottom: -24px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-width: 12px 15px;
  border-top-color: #24ABE2;
}

.testimonials-clean .item .author {
  margin-top: 28px;
  padding-left: 25px;
}

.testimonials-clean .item .name {
  /* font-family: 'SF Pro Display'; */
  font-size: 19px;
  font-weight: 800;
  color: white;
}

.testimonials-clean .item .title {
  /* font-family: 'Montserrat'; */
  font-size: 13px;
  color: white;
  font-weight: 100;
  margin-bottom: 30px;
  margin-top: -10px;
  font-style: italic;
}

.testimonials-clean .item .description {
  font-size: 15px;
  margin-bottom: 0;
  /* font-family: 'Montserrat'; */
}

.testimonials-clean .item img {
  max-width: 50px;
  float: left;
  margin-right: 12px;
  margin-top: -5px;
}

