.hero {
    margin-top: 5%;
    margin-left: 50px;
    position: absolute;
}

.cards {
    position: relative;
    margin-top: 15%;
    margin-left: 25px;
    margin-right: 25px;
}