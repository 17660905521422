@media (min-width: 1500px) {
    body {
        overflow-x: hidden;
        overflow-y: scroll !important;
        height: 100%;
    }

    body.menu_opened {
        overflow-x: hidden !important;
        width: 100%;
        overflow-y: scroll !important;
    }
}

html {
    min-height: 100%; /* make sure it is at least as tall as the viewport */
    position: relative;
}

body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

body.menu_opened {
    overflow-x: hidden !important;
    width: 100%;
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border: 2px solid white;
    border-radius: 5px;
}

.blog_section_heading {
    font-size: 40px;
    margin-top: 40px;
    margin-left: 20px;
}

.featured_blog_card {
    padding: 30px 20px;
}

.featured_blog_img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    background-size: 100%;
    background-position: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease;
}

.featured_blog_img:hover {
    background-size: 110%;
    box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.2);
}

.blog_post_title {
    font-size: 25px;
    margin-top: 20px;
}

.blog_post_title {
    font-size: 25px;
    margin-top: 20px;
    transition: all 0.2s ease;
}

.blog_post_title:hover {
    color: #24abe2;
}

.blog_post_desc {
    height: 100px;
    overflow: hidden;
}

.blog_date {
    color: #676767;
    opacity: 1;
    font-size: 14px;
    margin-top: -10px;
}

.editor {
    background-color: #f8f8f8;
    padding: 10px 20px;
}

.wrapper {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.post_title_input {
    border: 0;
    border-bottom: 0;
    font-size: 2rem;
    color: #24263c;
    font-family: "SF Pro Display";
    font-weight: 800;
    font-style: normal;
    transition: all 0.2s ease;
}

.post_cover_img {
    width: 100%;
    background-size: contain;
    margin-bottom: 50px;
}

.post_title {
    font-size: 3.5rem;
    color: #24263c;
    font-family: "SF Pro Display";
    font-weight: 800;
    font-style: normal;
}

.post_desc {
    font-size: 1.2rem;
    color: #24263c;
    opacity: 0.5;
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
}

.post_body p {
    font-family: "Montserrat";
    font-size: 1rem;
    color: #24263c;
    opacity: 0.6;
    margin-bottom: 1.9rem;
}

.post_body h1 {
    font-family: "SF Pro Display";
    color: #24263c;
    font-size: 3rem;
    font-weight: 600;
}

.post_body h2 {
    font-family: "SF Pro Display";
    color: #24263c;
    font-size: 2.5rem;
    font-weight: 600;
}

.post_body h3 {
    font-family: "SF Pro Display";
    color: #24263c;
    font-size: 2rem;
    font-weight: 600;
}

.post_body img {
    width: 100% !important;
}

.share_heading {
    font-family: "Montserrat";
    font-size: 17px;
    color: #24263c;
    opacity: 0.9;
    margin-bottom: -10px;
}

@media (max-width: 700px) {
    .share_heading {
        float: left;
    }
}

.post_title_input:focus {
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
}

.edit_post_link {
    color: #8b65ae !important;
    opacity: 1;
    transition: all 0.2s ease;
    text-decoration: none !important;
}

.edit_post_link:hover {
    color: #24263c !important;
    text-decoration: none !important;
}

.new_post_container {
    padding: 0px 400px;
}

@media (max-width: 1200px) {
    .new_post_container {
        padding: 0 100px;
    }
}

@media (max-width: 700px) {
    .new_post_container {
        padding: 0px 20px;
    }
}
