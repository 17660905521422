@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

*{
  font-family: Roboto;
}
.btn.btn-primary.main_purp_button {
  background-color: #8B65AE;
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
  margin-right: 20px;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1920px;
  padding-left: 0px;
  /*padding-right: 0px;*/
}

@media (min-width: 500px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1920px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 700px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1920px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.main_purp_button p {
  color: white;
  padding: 15px 35px;
  margin-bottom: 0px !important;
  font-size: 13px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: normal;
}

.main_white_button p {
  color: #8B65AE;
  padding: 15px 35px;
  margin-bottom: 0px;
  font-size: 13px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: normal;
}

@media (min-width: 300px) {
  .main_purp_button p {
    padding: 10px 30px;
    font-size: 10px !important;
    /* font-family: Montserrat, sans-serif; */
    font-weight: normal;
  }
}

@media (min-width: 900px) {
  .main_purp_button p {
    color: white;
    padding: 15px 35px;
    margin-bottom: 0px;
    font-size: 13px !important;
  }
}

.homepage_signin {
  margin-left: 20px !important;
}

.homepage_signin.menu_opened:hover {
  background-color: #f3f3f3 !important;
}

.homepage_signin.menu_opened {
  background-color: white !important;
}

.homepage_signin.menu_opened p {
  color: #24ABE2 !important;
}

.main_purp_button:hover {
  background-color: #643293;
  cursor: pointer;
}

.main_white_button:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.main_white_button {
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.main_purp_button {
  background-color: #8B65AE;
  border-radius: 5px;
  display: inline-block;
  box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.menu_icon {
  width: 20px;
  height: 13px;
  background-image: url("../img/menu%20icon.svg?h=f38a17ef13f57cc2517cfb6b9f8b7ed0");
  background-size: cover;
  float: right;
  margin-top: 2px;
  cursor: pointer;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.navbar-light .navbar-nav .menu:hover #white_icon {
  background-image: url("../img/menu%20icon%20hover%20white.svg?h=5732e2c785ee8d8317a5cdf5c6994ed9");
}

.navbar-light .navbar-nav .menu:hover .menu_icon {
  background-image: url("../img/menu%20icon%20hover.svg?h=d932dab0cc95986137afbaedc5aa50de");
}

@media (min-width:400px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (min-width:200px) {
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.container.hero_main_container {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 500px) {
  .container.hero_main_container {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0vw;
  }
}

@media (min-width: 700px) {
  .container.hero_main_container {
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 10vw;
  }
}

@media (min-width: 900px) {
  .container.hero_main_container {
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 8vw;
  }
}

@media (min-width: 1200px) {
  .container.hero_main_container {
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 4vw;
  }
}

@media (min-width: 1700px) {
  .container.hero_main_container {
    padding-left: 300px;
    padding-right: 90px;
    margin-top: 4vw;
  }
}

.outline_purp_button {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #8B65AE;
  display: inline-block;
  box-shadow: rgba(139,101,174,0.16) 3px 3px 6px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.outline_purp_button:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}

.outline_purp_button p {
  color: #8B65AE;
  padding: 15px 35px;
  margin-bottom: 0px;
  font-size: 13px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: normal;
}

@media (min-width: 300px) {
  .outline_purp_button p {
    padding: 10px 30px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .outline_purp_button p {
    color: #8B65AE;
    padding: 15px 35px;
    margin-bottom: 0px;
    font-size: 13px;
    /* font-family: Montserrat, sans-serif; */
    font-weight: normal;
  }
}

.paragraph_text {
  color: #24263C;
  font-size: 14px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: normal;
  opacity: 0.50;
}

@media (min-width: 300px) {
  .paragraph_text {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  .paragraph_text {
    font-size: 18px;
  }
}

@media (min-width: 1300px) {
  .paragraph_text {
    font-size: 14px;
  }
}

@media (min-width: 1700px) {
  .paragraph_text {
    font-size: 16px;
  }
}

.home_footer_links {
  padding: 0px 20px;
  color: white;
  vertical-align: top;
  font-size: 13px;
  border-left: 1px solid white;
}

@media (min-width: 300px) {
  .home_footer_links {
    padding: 0px 5px;
    margin-left: 0px;
    font-size: 8px;
  }
}

@media (min-width: 500px) {
  .home_footer_links {
    padding: 0px 10px;
    margin-left: 20px;
    font-size: 9px;
  }
}

@media (min-width: 1200px) {
  .home_footer_links {
    padding: 0px 20px;
    font-size: 11px;
    margin-left: 20px;
  }
}

@media (min-width: 1700px) {
  .home_footer_links {
    padding: 0px 20px;
    font-size: 13px;
    margin-left: 20px;
  }
}

.home_footer_links:hover {
  color: white;
  cursor: pointer !important;
}

#home_services_white li {
  color: white;
  border-right: 2px solid rgba(255,255,255,0.2);
}

.home_services li {
  float: left;
  width: 130px;
  border-right: 2px solid rgba(0,0,0,0.05);
  font-family: 'Montserrat';
  padding: 5px 0px;
  margin-left: 40px;
}

@media (min-width: 300px) {
  .home_services li {
    width: 70px;
    margin-left: 10px;
    font-size: 9px;
  }
}

@media (min-width: 700px) {
  .home_services li {
    width: 80px;
    margin-left: 20px;
    font-size: 10px;
  }
}

@media (min-width: 1100px) {
  .home_services li {
    width: 120px;
    margin-left: 40px;
    font-size: 14px;
  }
}

@media (min-width: 1300px) {
  .home_services li {
    width: 130px;
    font-size: 16px;
    margin-left: 40px;
  }
}

.home_social_icon:hover {
  cursor: pointer;
  opacity: 1;
}

.home_social_icon {
  margin-left: 0px;
  opacity: 0.8;
  font-size: 20px;
  color: white;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

@media (min-width: 300px) {
  .home_social_icon {
    margin-right: 20px;
    font-size: 20px;
  }
}

@media (min-width: 700px) {
  .home_social_icon {
    margin-right: 40px;
    font-size: 20px;
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .home_social_icon {
    margin-right: 30px;
    font-size: 24px;
  }
}

.home_heading {
  font-size: 2.5rem;
  color: #24263C;
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
}

@media (min-width: 300px) {
  .home_heading {
    font-size: 1.3rem;
    margin-top: 20px;
  }
}

@media (min-width: 700px) {
  .home_heading {
    font-size: 2rem;
    margin-top: 20px;
  }
}

@media (min-width: 900px) {
  .home_heading {
    font-size: 4rem;
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .home_heading {
    font-size: 3rem;
    margin-top: 0px;
  }
}

@media (min-width: 1700px) {
  .home_heading {
    font-size: 4rem;
  }
}

@media (min-width: 1700px) {
  .home_image {
    width: auto;
    height: 100%;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .home_image {
    width: auto;
    height: 100%;
    margin-top: 0px;
  }
}

.home_image {
  width: 100%;
  height: auto;
}

.h1, h1 {
  font-size: 26px;
  font-family: Roboto;
  font-weight: 200;
}

.home_phone_social {
  font-size: 26px;
  font-family: Roboto;
  font-weight: 200;
  text-align: right;
}

@media (min-width: 300px) {
  .home_phone_social {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 200;
    text-align: center;
  }
}

@media (min-width: 700px) {
  .home_phone_social {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 200;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .home_phone_social {
    font-size: 26px;
    font-family: Roboto;
    font-weight: 200;
  }
}

@media (min-width: 300px) {
  .h1, h1 {
    font-size: 15px;
    font-family: Roboto;
    font-weight: 200;
  }
}

@media (min-width: 700px) {
  .h1, h1 {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 200;
  }
}

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 26px;
    font-family: Roboto;
    font-weight: 200;
  }
}

@media (min-width: 1500px) {
  .container.home_info_container {
    position: absolute !important;
    bottom: 60px !important;
  }
}

.container.home_info_container {
  position: relative;
  bottom: 0px;
  margin-top: 20px;
  visibility: hidden;
}

@media (min-width: 1200px) {
  .home_social_column {
    padding: 20px;
    padding-right: 40px;
  }
}

.home_social_column {
  padding: 20px;
  padding-right: 40px;
}

@media (min-width: 300px) {
  .home_social_column {
    padding: 10px;
    /*padding-right: 40px;*/
    /*padding-bottom: 20px;*/
  }
}

@media (min-width: 500px) {
  .home_social_column {
    padding: 00px;
    padding-right: 40px;
    padding-left: 20px;
    padding-bottom: 10px;
  }
}

@media (min-width: 700px) {
  .home_social_column {
    padding: 00px;
    padding-right: 40px;
  }
}

@media (min-width: 1700px) {
  .home_footer_text {
    font-size: 12px !important;
  }
}

.home_footer_text {
  margin-bottom: 0px;
  font-size: 12px;
  opacity: 0.40;
}

@media (min-width: 300px) {
  .home_footer_text {
    font-size: 8px;
    text-align: center;
  }
}

@media (min-width: 300px) {
  .home_footer_text_right {
    text-align: center;
  }
}

@media (min-width: 500px) {
  .home_footer_text_right {
    font-size: 8px;
    text-align: right !important;
  }
}

.home_footer_text {
  font-size: 8px;
}

@media (min-width: 1200px) {
  .home_footer_text {
    font-size: 9px;
  }
}

.home_services {
  list-style-type: none;
  padding: 0px;
  padding-left: 0px;
}

@media (min-width: 300px) {
  .home_services {
    list-style-type: none;
    padding: 0px;
    padding-left: 15px;
  }
}

@media (min-width: 500px) {
  .home_services {
    list-style-type: none;
    padding: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .home_nibs_logo {
    width: 1.6vw !important;
  }
}

@media (max-width: 499px) {
  .home_nibs_logo {
    width: 10vw !important;
  }
}

.home_nibs_logo {
  width: 9vw;
}

@media (min-width: 500px) {
  .home_nibs_logo {
    width: 6vw !important;
  }
}

@media (min-width: 700px) {
  .home_nibs_logo {
    width: 6vw !important;
  }
}

.home_nibs_logo {
  width: 2vw;
}

@media (min-width: 500px) {
  .home_navbar {
    margin-top: 20px;
    width: 100%;
  }
}
@media (max-width: 900px){
  #hidden-items{
    display: none;
  }
}
.home_navbar {
  margin-top: 0px;
  width: 100%;
}

.menu {
  color: #24263C !important;
  font-size: 13px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: 800;
  font-family: Roboto;
}

.white_menu{
  color: white !important;
}

.menu_overlay {
  font-family: Roboto;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
  right: 220px;
  top: 30px;
  border-radius: 100%;
  background: transparent;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.1s ease-in-out, border-radius 0.2s ease-in-out 0s, top 0.2s ease-in-out, right 0.2s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.1s ease-in-out, border-radius 0.2s ease-in-out 0s, top 0.2s ease-in-out, right 0.2s ease-in-out;
}

.menu_overlay.menu_opened {
  background-color: #24ABE2;
  z-index: 2;
  width: 100%;
  top: 0px;
  right: 0px !important;
  height:137.5%;
  border-radius: 0px;
  font-family: Roboto;
}

@media (max-width:900px){
  .menu_overlay.menu_opened{
    height: 97.5%;
  }
}

.logo {
  width: 67px;
  height: 79px;
  background-image: url("../img/Logo.png?h=18a310849b680819b586ec9734a0c787");
  background-size: cover;
  float: right;
  margin-top: 2px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .logo {
    margin-left: -30px;
  }
}

@media (min-width: 700px) {
  .logo {
    margin-left: -40px;
  }
}

@media (max-width: 300px) {
  .logo {
    margin-left: -15px;
  }
}

.logo.menu_opened {
  background-image: url("../img/Logo_White.svg?h=3cdae5b9f43275ada9281078280fcd1d");
  width: 75px;
}

.navbar-light .navbar-nav .menu.menu_opened {
  color: white !important;
  font-family: Roboto;
}

.navbar-light .navbar-nav .menu.menu_opened:hover .menu_icon {
  background-image: url("../img/close_button.svg?h=687f4dc3a18b7f91211a3333b13709b4");
  background-size: contain;
  background-repeat: no-repeat;
}

.menu_icon.menu_opened {
  background-image: url("../img/close_button.svg?h=687f4dc3a18b7f91211a3333b13709b4") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.menu_items.menu_opened li a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #643293;
  margin-left: 20px;
}

@media (min-width: 700px) {
  .menu_items li {
    margin-bottom: 30px !important;
  }
}

.menu_items li {
  margin-bottom: 20px;
}

@media (min-width: 700px) {
  .menu_items li a {
    font-size: 35px !important;
  }
}

.menu_items li a {
  font-size: 25px;
  font-family: Roboto;
  font-weight: 500;
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0.2s, color 0.2s ease, margin 0.2s ease, visibility 0s;
  -webkit-transition: opacity 0.3s ease 0.2s, color 0.2s ease, margin 0.2s ease, visibility 0s;
}

.menu_items.menu_opened li a {
  opacity: 1;
  visibility: visible;
}

.menu_items ul {
  list-style-type: none;
  margin-left: -40px;
}

.menu_items.menu_opened {
  visibility: visible;
}

.menu_items {
  position: absolute;
  top: 350px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 50%;
  visibility: hidden;
  z-index: 4;
}

@media (min-width: 700px) {
  .menu_items {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(0%,0%);
    margin-left: 100px;
    text-align: left;
    margin-top: 150px;
    width: 30%;
  }
}

@media (min-width: 1700px) {
  .menu_items {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(0%,0%);
    margin-left: 300px;
    text-align: left;
    margin-top: 160px;
    width: 30%;
  }
}

.menu_graphic.menu_opened {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 700px) {
  .home_info_container.menu_opened{
    position: absolute;
    top: 600px;
  }
}

.menu_graphic {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  top: 90%;
  right: 0px;
  background-image: url("../img/wordcloud.svg");
  background-size: contain;
  width: 40vw;
  height: 40vw;
  z-index: 3;
  transition: opacity 0.3s ease 0.2s, visibility 0s;
  -webkit-transition: opacity 0.3s ease 0.2s, visibility 0s;
}

@media (min-width: 700px) {
  .menu_graphic {
    top: 50px !important;
    right: 0px !important;
    margin-left: 0px !important;
    width: 40vw !important;
    height: 40vw !important;
  }
}

@media (min-width: 300px) {
  .menu_graphic {
    top: 700px;
    right: 25%;
    margin-left: -50px;
    width: 50vw;
    height: 50vw;
  }
}

.services {
  padding: 20px;
  margin-bottom: 50px;
}

#white_socials a i {
  color: white;
}

.home_phone_social.menu_opened a i {
  color: white;
}

.home_phone_social.menu_opened {
  color: white;
}

#white_socials {
  color: white;
  align-items: center;
}

/* #servicesCarousel #carouselControls {
  display: none;
} */

.carousel-indicators li {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-bottom: -10px;
  border-radius: 100%;
}

#services_mob {
  display: block;
}

@media (min-width: 700px) {
  #services_mob {
    display: none;
  }
}

#servicesCarousel {
  display: none;
}

@media (min-width: 700px) {
  #servicesCarousel {
    display: block;
  }
}

.signup_container .paragraph_text a {
  color: #8B65AE;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.signup_container .paragraph_text a:hover {
  color: #643293;
}

.join_quarkme_heading {
  font-family: Roboto;
  font-weight: 900;
  color: white;
  font-size: 55px;
}

@media (min-width: 500px) {
  .join_quarkme_heading {
    font-size: 30px !important;
    display: block;
    margin: auto;
    margin-top: -10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 300px) {
  .join_quarkme_heading {
    font-size: 20px;
    display: block;
    margin: auto;
    margin-top: -10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .join_quarkme_heading {
    font-family: Roboto;
    font-weight: 900;
    color: white;
    font-size: 55px !important;
  }
}

@media (min-width: 900px) {
  .join_quarkme_heading {
    display: block;
    margin: auto;
    margin-top: -10px;
    margin-bottom: 20px;
  }
}

.signup_container {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
}

@media (min-width: 500px) {
  .signup_container {
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
  }
}

@media (max-width: 500px) {
  .signup_container {
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.textarea {
  padding-top: 15px !important;
}

input[placeholder]{
  width: 100% !important;
}

.form-group input, .textarea {
  background-color: #F8F8F8;
  border: 2px solid transparent;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  padding: 22px;
  margin-top: 25px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.form-group input:focus, .textarea:focus {
  border: 2px solid rgb(139,101,174, 0.5);
  box-shadow: none;
  background-color: #F8F8F8;
}

@media (min-width: 500px) {
  .form-group input, .textarea {
    background-color: #F8F8F8;
    border: 2px solid transparent;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    padding: 22px;
    margin-top: 25px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
  }
}

@media (max-width: 500px) {
  .form-group input, .textarea {
    font-size: 14px;
    padding: 15px;
    margin-top: 10px;
  }
}

#checkbox {
  background-color: red;
}

#checkbox:checked {
  background-color: #8B65AE;
}

.team_image {
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 100%;
  background-position: center;
  /*filter: grayscale(1);*/
  background-size: cover;
}

.services h1 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 800;
  color: white;
}

.services p {
  font-family: 'Montserrat';
  font-size: 17px;
  color: white;
  margin-bottom: 30px;
}

.content_cutter{
  height: 120px;
  overflow-y: scroll;
  margin-bottom: 30px;
}

.content_cutter::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(0,0,0,0);
}

.content_cutter::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.team h1 {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 800;
  color: white;
}

.team p {
  font-family: 'Montserrat';
  font-size: 16px;
  color: white;
  font-weight: 100;
  margin-bottom: 30px;
  margin-top: -10px;
  font-style: italic;
}

#blue_button {
  background-color: #24ABE2;
}

#blue_button:hover {
  background-color: #2299C9;
}

form .form-group input::placeholder, .textarea::placeholder {
  color: rgba(0,0,0,0.4);
}

.team {
  padding: 10px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

#white_icon {
  background-image: url("../img/menu%20icon%20white.svg?h=4303b395e2b9127b4fa8295501ce9046");
}

.page_headings {
  font-family: Roboto;
  font-weight: 900;
  color: white;
  font-size: 55px;
}

@media (min-width: 500px) {
  .page_headings {
    font-family: Roboto;
    font-weight: 900;
    font-size: 55px !important;
    text-align: left !important;
    display: inline-block;
    margin: 0 !important;
  }
}

@media (min-width: 300px) {
  .page_headings {
    font-family: Roboto;
    font-size: 45px;
    text-align: center;
    display: block;
    margin: auto;
    margin-bottom: 30px;
    margin-top: -20px;
  }
}

.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ECECEC;
  box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}

#customers-testimonials .shadow-effect p {
  font-family: 'Montserrat Light';
  font-size: 17px;
  color: black;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}

.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  font-family: 'Montserrat Regular';
  background: #3bffbe;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);
}

#customers-testimonials .item {
  text-align: center;
  padding: 10px;
  margin-bottom: 80px;
  opacity: .2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1.0, 1.0, 1);
  transform: scale3d(1.0, 1.0, 1);
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin: 0 auto 17px;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span, #customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #3bffbe;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}

#customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #3bffbe;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}

.vendor_img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 10px;
}

@media (min-width: 500px) {
  .vendor_img {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
}

.vendor_h1 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  color: #24263C;
}

.vendor_price {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 400;
  color: #24ABE2;
}

.vendor_details {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 10px;
}

.vendor_details li {
  padding: 0px;
  margin-bottom: 0px;
  font-family: 'Montserrat';
  font-size: 15px;
  color: rgba(36,38,60,1);
}

.vendor_details li span {
  color: rgba(36,38,60,0.5);
}

.vendor_details li i {
  color: #FFC107;
}

.vendor_services {
  list-style-type: none;
  padding: 0px;
}

.vendor_services li {
  float: left;
  padding: 2px 8px;
  color: white;
  font-family: Roboto;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10%;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #8B65AE;
}

.vendors_row {
  overflow-y: scroll;
  height: 100%;
}

.vendors_row::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0,0,0,0);
}

.vendors_row::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

@media (min-width: 900px) {
  .vendors_row {
    overflow-y: scroll;
    height: 500px;
  }
}

@media (min-width: 900px) {
  .vendor_submit {
    margin-top: 15px !important;
  }
}

.vendor_submit {
  margin-top: 0px;
}

.sticky-top {
  z-index: 1;
}

.toast-success {
  border-radius: 10px !important;
  border: none !important;
  padding-left: 20px !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.toast-alert {
  border-radius: 10px !important;
  border: none !important;
  padding-left: 20px !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  background-color: var(--secondary);
}

.text-left.home_heading{
  font-family: Roboto;
}

@media (max-width: 900px) {
.menu_graphic{
  display: none;
}
}

/* Menu Partner Dropdown */

.partner-dropdown ul {
  position: absolute;
  background-color: #24ABE2;
  width: 100%;
  margin-top: 2%;
  margin-left: 0;
  /* padding-left: 5%; */
  font-size: 1.5rem;
}

.partner-dropdown ul .partner-dropdown-item{
  font-size: 1.75rem !important;
  font-weight: 300 !important;
  margin-left: 5%;
  
}

#multiselectContainerReact input {
  cursor: pointer;
}

#multiselectContainerReact::after {
  content: '>';
  font-size: 2rem;
  color: #aaa;
  display: block;
  width: 20%;
  height: 100%;
  z-index: 999999;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}