.popup-container{
    display: flex;
    flex-direction: column;
    background:rgba(0, 0, 0, 0.8);
    width:100%;
    height:100%;
    z-index: 5;
    position: fixed;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.image-container {
  position: relative;
  height: 90%;
  width: 25%;
  background: url('../img/QuarkMe Website - MyEra announcement.png') no-repeat center center/contain;
}

@media(max-width: 1000px) {
  .image-container {
    width: 90%;
    height: 90%;
  }
}
/* img{
    transform:scale(0.9);
    -webkit-transform: scale(0.09);
}
 */


.close-button{
    background: #24abe2;
    color: white;
    height: 1.8rem;
    width: 1.8rem;
    /*border-radius: 50%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 2.2%;
}

@media(max-width: 1000px) {
  .close-button {
    left: 86%;
  }
}

.main_popup_button p {
    color: white;
    padding: 10px 30px;
    margin-bottom: 0px !important;
    font-size: 13px;
    /* font-family: Montserrat, sans-serif; */
    font-weight: normal;
  }

  @media (min-width: 300px) {
    .main_popup_button p {
      padding: 7px 25px;
      font-size: 10px !important;
      /* font-family: Montserrat, sans-serif; */
      font-weight: normal;
    }
  }
  
  @media (min-width: 900px) {
    .main_popup_button p {
      color: white;
      padding: 10px 30px;
      margin-bottom: 0px;
      font-size: 13px !important;
    }
  }

  .main_popup_button {
    background-color: #643293;
    border-radius: 5px;
    display: inline-block;
    box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
    position: absolute;
    z-index:50;
    align-self: center;
    top: 35%;
    left: 39%;
    display: flex;
    text-align: left;
  }
  @media (max-width: 300px) {
    .main_popup_button  {
      left:15%;
    }
  }
   /*@media(max-width: 1500px) {
    .main_popup_button {
      top: 35%;
      left: 13%;
    }
  } */
