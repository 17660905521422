@media (min-width: 1500px) {
    body {
      overflow-x: hidden;
      overflow-y: hidden !important;
      height: 100%;
    }
  
    body.menu_opened {
      overflow-x: hidden !important;
      width: 100%;
      overflow-y: hidden !important;
    }
  }
  
  html{
    min-height:100%;/* make sure it is at least as tall as the viewport */
    position:relative;
  }
  
  body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  
  body.menu_opened {
    overflow-x: hidden !important;
    width: 100%;
    overflow-y: scroll;
  }
  