.home_brands {
  color: #313437;
  background-color: #fff;
}

.home_brands p {
  margin-right: 5%;
}

.home_brands a {
  display: inline-block;
  padding: 0px 0;
}

@media (max-width:767px) {
  .home_brands a {
    padding: 0px;
  }
}

.home_brands a img {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 10px;
  opacity: 0.50;
  filter: grayscale(90%) hue-rotate(0deg) saturate(69%);
  vertical-align: middle;
  -webkit-transition: all 0.2s ease;
}

@media (max-width: 499px) {
  .home_brands a img {
    width: 18vw;
  }
}

@media (min-width: 500px) {
  .home_brands a img {
    width: 11.5vw;
  }
}

@media (min-width: 1200px) {
  .home_brands a img:nth-child(4) {
    width: 3vw !important;
  }
}

@media (min-width: 1200px) {
  .home_brands a img {
    width: 6vw !important;
  }
}

@media (min-width: 700px) {
  .home_brands a img {
    width: 11vw;
  }
}

.home_brands a img:hover {
  opacity: 1;
  filter: none;
}

.dropdownselect {
  background-color: #F8F8F8;
  border: 2px solid transparent;
  font-family: 'Montserrat';
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  margin-top: 0px;
}

@media (min-width: 500px) {
  .dropdownselect {
    margin-top: 27px;
    font-size: 16px;
    height: 45px;
  }
}

.dropdownselect:focus {
  border: 2px solid rgb(139,101,174, 0.5);
  box-shadow: none;
  background-color: #F8F8F8;
}

.vendor_container {
  margin-bottom: 20px;
}

.carousel-control-next, .carousel-control-prev {
  top: 100%;
}

.carouselPrevText, .carouselNextText {
  font-family: 'Montserrat';
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  margin: 20px;
}

