label {
  margin-top: 30px;
  display: inline-block;
  margin-bottom: .5rem;
  color: #24263C;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 200;
  opacity: 1;
}

.check input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, .check input {
  overflow: visible;
}

.check input {
  padding: 0.15rem !important;
  border: 0;
  border-bottom: 1px solid #E0E0E0;
}

.check input:focus {
  border: 0 !important;
  border-bottom: 1px solid #8B65AE !important;
  outline: none !important;
  color: #48CCFF;
}

label, .check input, textarea {
  transition: all 0.2s !important;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.check input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, .check input {
  overflow: visible;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.check input {
  padding: 0.15rem !important;
  border: 0;
  border-bottom: 1px solid #E0E0E0;
}

.check input:focus {
  border: 0 !important;
  border-bottom: 1px solid #8B65AE !important;
  outline: none !important;
  color: #48CCFF;
}

.check {
}

label, .check input, textarea {
  transition: all 0.2s !important;
}

.check {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #BDBDBD;
}

.check:hover input ~ .checkmark {
  background-color: #ebebeb;
}

.check input:checked ~ .checkmark {
  background-color: #8B65AE;
  border: 1px solid #8B65AE;
}

.check input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

