.services p {
  font-family: 'Montserrat';
  font-size: 17px;
  color: white;
  margin-bottom: 30px;
}

#blue_button {
  background-color: #24ABE2;
}

#blue_button:hover {
  background-color: #2299C9;
}

@media (min-width: 300px) {
  #blue_button {
    font-size: 200px;
  }
}

@media (min-width: 500px) {
  #blue_button {
    font-size: 16px;
  }
}


.main_purp_button p {
  color: white;
  padding: 15px 35px;
  margin-bottom: 0px;
  font-size: 13px;
  /* font-family: Montserrat, sans-serif; */
  font-weight: normal;
}

@media (min-width: 300px) {
  .main_purp_button p {
    padding: 10px 30px;
    font-size: 14px;
  }
}

@media (min-width: 900px) {
  .main_purp_button p {
    color: white;
    padding: 15px 35px;
    margin-bottom: 0px;
    font-size: 13px;
    /* font-family: Montserrat, sans-serif; */
    font-weight: normal;
  }
}

.main_purp_button:hover {
  background-color: #643293;
  cursor: pointer;
}

.main_purp_button {
  background-color: #8B65AE;
  border-radius: 5px;
  display: inline-block;
  box-shadow: rgba(0,0,0,0.16) 3px 3px 6px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

